<template>
  <div class="applyProductCombo-container">
    <div class="comboList">
      <div class="header2 product_hrader">
        <van-nav-bar
          title="创建产品"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        ></van-nav-bar>
      </div>
      <van-form validate-first class="product_cs1" style="margin-bottom: 100px">
        <van-cell title="商品图片:" class="titleImg_cs">
            <template #title>
                商品图片: <span style="color: red; font-size: 16px;">*</span>
            </template>
          <el-upload
            style="display: inline-block; padding-left: 18px"
            :limit="max"
            :action="
              $store.state.imgUpdateUrl +
              '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
            "
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :file-list="forms.commodityImg"
            :on-success="
              (response) => {
                return productRowUpdateFlie(response);
              }
            "
            :on-exceed="fnmax"
            :on-remove="
              (file, fileList) => {
                return productHandleRemove(file, fileList);
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>
        <van-cell-group>
          <van-cell title="产品名称:">
            <template #title>
                产品名称: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <van-field
              v-model="forms.original"
              maxlength="30"
              placeholder="请输入商品名称"
            />
          </van-cell>
        </van-cell-group>

        <van-cell-group>
          <van-cell
            title="产品经理:"
            class="van-ellipsis"
            @click="show1 = true"
          >
            <template #title>
                产品经理: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <div class="sk2">
              <span
                style="margin-right: 15px; color: black"
                v-if="forms.manager"
                >{{ forms.manager }}</span
              >
              <span style="margin-right: 15px" v-else>请选择产品经理</span>
              <van-icon name="arrow" />
            </div>
          </van-cell>
          <van-action-sheet
            v-model="show1"
            :close-on-click-overlay="false"
            cancel-text="取消"
            close-on-click-action
            :actions="list.listManager"
            @cancel="onCancel"
            @select="valuation"
          />
        </van-cell-group>
        <van-cell-group>
          <van-cell title="商品大类:" class="van-ellipsis" @click="show = true">
            <template #title>
                商品大类: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <div class="sk2">
              <span
                style="margin-right: 15px; color: black"
                v-if="forms.commodityContractType"
                >{{ forms.commodityContractType }}</span
              >
              <span style="margin-right: 15px" v-else>请选择商品大类</span>
              <van-icon name="arrow" />
            </div>
          </van-cell>
          <van-action-sheet
            v-model="show"
            :close-on-click-overlay="false"
            cancel-text="取消"
            close-on-click-action
            :actions="list.actions"
            @cancel="onCancel"
            @select="onSelect"
          />
        </van-cell-group>
        <van-cell-group>
          <van-cell title="细分品类:" class="van-ellipsis" @click="showSelect">
            <template #title>
                细分品类: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <div class="sk2">
              <span
                style="margin-right: 15px; color: black"
                v-if="forms.commodityContractType2"
                >{{ forms.commodityContractType2 }}</span
              >
              <span style="margin-right: 15px" v-else>请选择细分品类</span>
              <van-icon name="arrow" />
            </div>
          </van-cell>
          <van-action-sheet
            v-model="show2"
            :close-on-click-overlay="false"
            cancel-text="取消"
            close-on-click-action
            :actions="list.commodityContractTypeList2"
            @cancel="onCancel"
            @select="getCommodityContractTypeList"
          />
        </van-cell-group>
        <van-cell-group>
          <van-cell
            title="所属品牌:"
            class="van-ellipsis"
            @click="show3 = true"
          >
            <template #title>
                所属品牌: <span style="color: red; font-size: 16px;">*</span>
            </template>
            <div class="sk2">
              <span
                style="margin-right: 15px; color: black"
                v-if="forms.merchantBrand"
                >{{ forms.merchantBrand }}</span
              >
              <span style="margin-right: 15px" v-else>请选择所属品牌</span>
              <van-icon name="arrow" />
            </div>
          </van-cell>
          <van-action-sheet
            v-model="show3"
            :close-on-click-overlay="false"
            cancel-text="取消"
            close-on-click-action
            :actions="list.merchantBrandList"
            @cancel="onCancel"
            @select="onmerchantBrand"
          />
        </van-cell-group>
        <van-cell-group>
          <van-cell title="特点描述:">
            <div class="sk2 textarea_cs">
              <van-field
                v-model="forms.message"
                rows="2"
                autosize
                type="textarea"
                maxlength="500"
                placeholder="请输入特点描述"
                show-word-limit
              />
            </div>
          </van-cell>
        </van-cell-group>
        <van-cell title="质检报告:" class="titleImg_cs">
          <el-upload
            style="display: inline-block; padding-left: 18px"
            :limit="max"
            :action="
              $store.state.imgUpdateUrl +
              '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
            "
            :before-upload="beforeAvatarUpload1"
            list-type="picture-card"
            :file-list="forms.qualityImg"
            :on-success="
              (response) => {
                return productRowUpdateFlie2(response);
              }
            "
            :on-exceed="fnmax"
            :on-remove="
              (file, fileList) => {
                return productHandleRemove2(file, fileList);
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>
        <van-cell title="商品证书:" class="titleImg_cs">
          <el-upload
            style="display: inline-block; padding-left: 18px"
            :limit="max"
            :action="
              $store.state.imgUpdateUrl +
              '/f/upload/file/upload?fileTypeEnum=INTERNALLY'
            "
            :before-upload="beforeAvatarUpload"
            list-type="picture-card"
            :file-list="forms.certificateImg"
            :on-success="
              (response) => {
                return productRowUpdateFlie1(response);
              }
            "
            :on-exceed="fnmax"
            :on-remove="
              (file, fileList) => {
                return productHandleRemove1(file, fileList);
              }
            "
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </van-cell>
      </van-form>
      <van-tabbar>
        <div style="width: 100%; text-align: center">
          <van-button
            style="background-color: #8585ad; color: white; width: 20%; border-radius: 6px;"
            @click="onClickLeft"
            >取消</van-button
          >
          <van-button
            style="background-color: #00ccff; color: white; width: 20%; border-radius: 6px;"
            @click="confirm"
            v-if="disabledBtn"
            >提交</van-button
          >
          <van-button
            style="background-color: #00ccff; color: white; width: 20%; border-radius: 6px;"
            disabled
            v-else
            >提交</van-button
          >
        </div>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { Empty, Notify } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      disabledBtn: true,
      show: false,
      show1: false,
      show2: false,
      show3: false,
      imgType: ['image/jpg','image/jpeg','image/png','image/gif'],
      imgType1: ['image/jpg','image/jpeg','image/png','image/gif','application/pdf','image.PDF'],
      list: {
        listManager: [], //产品经理
        listManagerId: [], //产品经理请求数据
        actions: [], //商品大类
        commodityContractTypelist: [], //商品大类请求数据
        merchantBrandList: [], //所属品牌
        commodityContractTypeList2: [], //细分品类
        merchantBrandListId: [], //所属品牌请求数据
        commodityContractTypeListId2: [], //细分品类请求数据
      },
      max: 6,
      forms: {
        message: "", //特点描述
        commodityContractTypeId: "", //商品大类Id
        original: "", //商品名称
        commodityContractType: "", //商品大类
        manager: "", //产品经理
        merchantBrand: "", //所属品牌
        commodityContractType2: "", //细分品类
        commodityImg: [], //商品图片
        certificateImg: [], //商品证书
        qualityImg: [], //质检报告
      },
    };
  },
  created() {
    // 获取产品经理
    this.getManager();
    // 获取商品大类
    this.getCommoityList();
    // 获取所属品牌
    this.getMerchantBrand();
  },
  methods: {
    showSelect() {
      if (this.forms.commodityContractType == "") {
        Notify({ type: "danger", message: "请先选择商品大类!!!" });
        return false;
      }
      this.show2 = true;
    },
    // 获取产品经理
    getManager() {
      this.$http
        .ajax(
          "/ffkj-main/merchantManager/listManager?merchantId=" +
            localStorage.getItem("merchantId"),
          {},
          "get",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            this.list.listManagerId = res.data;
            for (var i = 0; i < res.data.length; i++) {
              let obj = new Object();
              obj.name = res.data[i].name;
              this.list.listManager.push(obj);
            }
          } else {
            Notify({ type: "danger", message: res.message });
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer);
            }.bind(this),
            3000
          );
        });
    },
    //  获取商品大类数据
    getCommoityList() {
      this.$http
        .ajax("/ffkj-main/commodityContractType/list", {}, "get", {})
        .then((res) => {
          if (res.code == 0) {
            this.list.commodityContractTypelist = res.data;
            for (var i = 0; i < res.data.length; i++) {
              let obj = new Object();
              obj.name = res.data[i].commodityContractTypeName;
              this.list.actions.push(obj);
            }
          } else {
            Notify({ type: "danger", message: res.message });
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer);
            }.bind(this),
            3000
          );
        });
    },
    //  获取细分品类
    getCommodityContractType() {
      this.$http
        .ajax(
          "/ffkj-main/industryType/list?commodityContractTypeId=" +
            this.forms.commodityContractTypeId,
          {},
          "get",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            this.list.commodityContractTypeListId2 = res.data;
            for (var i = 0; i < res.data.length; i++) {
              let obj = new Object();
              obj.name = res.data[i].commodityContractTypeName;
              this.list.commodityContractTypeList2.push(obj);
            }
          } else {
            Notify({ type: "danger", message: res.message });
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer);
            }.bind(this),
            3000
          );
        });
    },
    // 获取所属品牌
    getMerchantBrand() {
      this.$http
        .ajax(
          "/ffkj-main/merchantBrand/selector?merchantId=" +
            localStorage.getItem("merchantId"),
          {},
          "get",
          {}
        )
        .then((res) => {
          if (res.code == 0) {
            this.list.merchantBrandListId = res.data;
            for (var i = 0; i < res.data.length; i++) {
              let obj = new Object();
              obj.name = res.data[i].name;
              this.list.merchantBrandList.push(obj);
            }
          } else {
            Notify({ type: "danger", message: res.message });
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer);
            }.bind(this),
            3000
          );
        });
    },
    // 获取产品经理
    valuation(item) {
      this.forms.manager = item.name;
    },
    // 获取选择的商品大类
    onSelect(item) {
      this.forms.commodityContractType2 = '';
      this.forms.commodityContractType = item.name;
      this.list.commodityContractTypeList2 = [];
      for (let i = 0; i < this.list.commodityContractTypelist.length; i++) {
        if (
          this.list.commodityContractTypelist[i].commodityContractTypeName ==
          item.name
        ) {
          this.forms.commodityContractTypeId =
            this.list.commodityContractTypelist[i].id;
        }
      }
      // 获取细分品类
      this.getCommodityContractType();
    },
    // 获取所属品牌
    onmerchantBrand(item) {
      this.forms.merchantBrand = item.name;
    },
    // 获取所属品牌
    onmerchantBrand(item) {
      this.forms.merchantBrand = item.name;
    },
    // 获取细分品类
    getCommodityContractTypeList(item) {
      this.forms.commodityContractType2 = item.name;
    },
    // 取消
    onCancel() {},
    //  返回事件
    onClickLeft() {
      this.$router.push({
        path: "/product",
        query: {
          active: 1,
        },
      });
    },
    // 上传图片格式效验
    beforeAvatarUpload(file) {
        if ( this.imgType.indexOf(file.type) == -1 ) {
            Notify({ type: 'danger', message: '上传图片只能是JPG/JPEG/PNG/GIF格式' })
            return false
        }
        if ( file.size / 1024 / 1024 > 50 ) {
            Notify({ type: 'danger', message: '上传图片大小不能超过50MB' })
            return false
        }
    },
    // 上传质检报告格式校验
    beforeAvatarUpload1(file) {
        if ( this.imgType1.indexOf(file.type) == -1 ) {
            Notify({ type: 'danger', message: '上传图片只能是JPG/JPEG/PNG/GIF/PDF格式' })
            return false
        }
        if ( file.size / 1024 / 1024 > 50 ) {
            Notify({ type: 'danger', message: '上传图片大小不能超过50MB' })
            return false
        }
    },
    // 最多上传数量
    fnmax(el) {
      Notify({ type: "danger", message: "最多允许上传" + this.max + "张" });
    },
    // 上传商品图片
    productRowUpdateFlie(response) {
      this.forms.commodityImg.push({ url: response });
      if(this.forms.commodityImg.length >= this.max){
        Notify({ type: "warning", message: "上传数量已达上限!!!" });
      }
    },
    // 删除商品图片
    productHandleRemove(file, fileList) {
      this.forms.commodityImg = fileList;
    },
    // 上传商品证书
    productRowUpdateFlie1(response) {
      this.forms.certificateImg.push({ url: response });
      if(this.forms.certificateImg.length >= this.max){
        Notify({ type: "warning", message: "上传数量已达上限!!!" });
      }
    },
    // 删除商品证书
    productHandleRemove1(file, fileList) {
      this.forms.certificateImg = fileList;
    },
    // 上传质检报告
    productRowUpdateFlie2(response) {
      this.forms.qualityImg.push({ url: response });
      if(this.forms.qualityImg.length >= this.max){
        Notify({ type: "warning", message: "上传数量已达上限!!!" });
      }
    },
    // 删除质检报告
    productHandleRemove2(file, fileList) {
      this.forms.qualityImg = fileList;
    },
    // 提交审核
    confirm() {
      if (this.forms.commodityImg.length <= 0) {
        Notify({ type: "danger", message: "请至少上传一张商品图片!!!" });
        return false;
      }
      if (this.forms.original == "") {
        Notify({ type: "danger", message: "商品名称不能为空!!!" });
        return false;
      }
      if (this.forms.manager == "") {
        Notify({ type: "danger", message: "请选择产品经理!!!" });
        return false;
      }
      if (this.forms.commodityContractType == "") {
        Notify({ type: "danger", message: "请选择商品大类!!!" });
        return false;
      }
      if (this.forms.commodityContractType2 == "") {
        Notify({ type: "danger", message: "请选择细分品类!!!" });
        return false;
      }
      if (this.forms.merchantBrand == "") {
        Notify({ type: "danger", message: "请选择所属品牌!!!" });
        return false;
      }
      this.disabledBtn = false;
      let form = {
        listManagerId: "",//产品经理id
        commodityImg: [],//商品图片
        commodityContractTypeId: "",//商品大类id
        commodityContractTypesId: "",//细分品类id
        merchantBrandId: "",//所属品牌id
        certificateImg: [],//商品证书
        qualityImg: [],//质检报告
      };
      for (let i = 0; i < this.list.listManagerId.length; i++) {
        if (this.list.listManagerId[i].name == this.forms.manager) {
            form.listManagerId = this.list.listManagerId[i].id;
        }
      }
      for (let i = 0; i < this.list.commodityContractTypelist.length; i++) {
        if (this.list.commodityContractTypelist[i].commodityContractTypeName ==this.forms.commodityContractType) {
          form.commodityContractTypeId =this.list.commodityContractTypelist[i].id;
        }
      }
      for (let i = 0; i < this.list.commodityContractTypeListId2.length; i++) {
        if (this.forms.commodityContractType2 == this.list.commodityContractTypeListId2[i].commodityContractTypeName) {
          form.commodityContractTypesId = this.list.commodityContractTypeListId2[i].id;
        }
      }
      for (let i = 0; i < this.list.merchantBrandListId.length; i++) {
        if (this.forms.merchantBrand == this.list.merchantBrandListId[i].name) {
          form.merchantBrandId = this.list.merchantBrandListId[i].id;
        }
      }
      for (let i = 0; i < this.forms.commodityImg.length; i++) {
        form.commodityImg.push(this.forms.commodityImg[i].url || this.forms.commodityImg[i]);
      }
      if (this.forms.certificateImg.length > 0) {
        for (let i = 0; i < this.forms.certificateImg.length; i++) {
          form.certificateImg.push(this.forms.certificateImg[i].url);
        }
      }
      if (this.forms.qualityImg.length > 0) {
        for (let i = 0; i < this.forms.qualityImg.length; i++) {
          form.qualityImg.push(this.forms.qualityImg[i].url);
        }
      }
      this.$http
      .ajax('/ffkj-main/merchantCommodity/add',
        {
          commodityName: this.forms.original,
          merchantBrandId: form.merchantBrandId,
          commodityContractTypeId: form.commodityContractTypeId,
          industryTypeId: form.commodityContractTypesId,
          commodityImgs: form.commodityImg,
          commodityCertificates: form.certificateImg,
          description: this.forms.message,
          merchantId: localStorage.getItem("merchantId"),
          inspectionReport: form.qualityImg,
          sysUserId: form.listManagerId,
      },'post',{}).then(res=>{
          if( res.code == 0 ){
              Notify({ type: "success", message: "创建成功!!!" });
              setTimeout(()=>{
                  this.onClickLeft();
              },400)
          }
          else{
            this.disabledBtn = true;
            Notify({ type: "danger", message: res.data });
          }
          var timer = setTimeout(function(){
              window.clearTimeout(timer)
          }.bind(this), 300)
      })
    },
  },
};
</script>

<style scoped>

</style>
